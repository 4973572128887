import React from "react"
import { Container, Row } from "react-grid-system"
import FullWidthSection from "../../layout/fullWidthSection"
import InnerContentContainer from "../../layout/innerContentContainer"
import Column from "../../layout/column"
import Markdown from "react-markdown"

import { defaultRenderersDarkBackground } from "../../../constants/mdxRenderers"

const InfoPanelOne = ({ panel }) => {
  return (
    <FullWidthSection blue>
      <InnerContentContainer>
        <Container style={{ flex: 1 }}>
          <Row>
            <Column sm={12} md={12} lg={6}>
              <img
                className="img-responsive"
                src={panel.image.publicURL}
                alt={panel.imageAlt}
              />
            </Column>
            <Column sm={12} md={12} lg={6}>
              <h3 className="white-text">{panel.subheader}</h3>
              <Markdown
                children={panel.blurb}
                components={defaultRenderersDarkBackground}
              />
            </Column>
          </Row>
        </Container>
      </InnerContentContainer>
    </FullWidthSection>
  )
}

export default InfoPanelOne
