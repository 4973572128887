import React from "react"
import { graphql } from "gatsby"
import * as queryString from "query-string"
import Layout from "../components/layout"
import Features from "../components/home/features"
import Hero from "../components/home/hero"
import SEO from "../components/seo"
import InfoPanelOne from "../components/home/infoPanelOne"
import SocialProof from "../components/home/socialProof"
import Pricing from "../components/pricing/index"

import Testimonial from "../components/testimonials/testimonial"
import testimonialPages from "../constants/testimonialPages"

import {
  LOGIN_LINK,
  SIGN_UP_LINK,
  APP_STORE_LINK,
  PLAY_STORE_LINK,
} from "../constants/linkConstants"

const IndexPage = ({ data, location }) => {
  const {
    meta: {
      siteseo: { description, titleseo },
    },
  } = data
  const searchParams = queryString.parse(location.search)
  const { utm_campaign, utm_medium, utm_source } = searchParams

  let signupLink = SIGN_UP_LINK
  let loginLink = LOGIN_LINK
  let appStoreLink = APP_STORE_LINK
  let playStoreLink = PLAY_STORE_LINK

  if (utm_campaign && utm_medium && utm_source) {
    signupLink = `${SIGN_UP_LINK}?utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}`
    loginLink = `${LOGIN_LINK}?utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}`
    appStoreLink = `${APP_STORE_LINK}?utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}`
    playStoreLink = `${PLAY_STORE_LINK}?utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}`
  }
  return (
    <>
      <SEO title={titleseo} description={description} />
      <Layout loginLink={loginLink} signupLink={signupLink}>
        <Hero
          signupLink={signupLink}
          appStoreLink={appStoreLink}
          playStoreLink={playStoreLink}
        />
        <InfoPanelOne />
        <Features />
        <Testimonial pageID={testimonialPages.CONSTRUCTION} />
        <SocialProof />
        <Pricing signupLink={signupLink} />
      </Layout>
    </>
  )
}

export const query = graphql`
  {
    meta: strapiSiteOverallMeta {
      siteseo {
        titleseo
        description
      }
    }
  }
`

export default IndexPage
