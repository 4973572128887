import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import loadable from "@loadable/component"

import LoadingPlaceHolder from "../loading/loadingPlaceholder"

const LoadableArea = loadable(() =>
  import("../page-components/testimonial/loadableArea")
)

// pageID determines which testimonial to use
const Testimonial = ({ pageID }) => {
  const query = graphql`
    {
      allStrapiTestimonial(filter: { pageID: { eq: "construction" } }) {
        nodes {
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          businessName
          testimonialText
          userName
        }
      }
    }
  `

  const { allStrapiTestimonial } = useStaticQuery(query)
  const testimonialData = allStrapiTestimonial.nodes[0]

  return (
    <LoadableArea
      fallback={LoadingPlaceHolder}
      testimonialData={testimonialData}
    />
  )
}

export default Testimonial
