import React from "react"
import { graphql, useStaticQuery } from "gatsby"

// import loadable from "@loadable/component"

// const LoadableArea = loadable(() => import("./LoadableArea"))

import LoadableArea from "./LoadableArea"

const query = graphql`
  {
    panel: strapiInfoPanelOne {
      blurb
      subheader
      imageAlt
      image {
        publicURL
      }
    }
  }
`

const InfoPanelOne = () => {
  const { panel } = useStaticQuery(query)
  return <LoadableArea panel={panel} />
}

export default InfoPanelOne
